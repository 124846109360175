import {Controller} from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static values = {
        type: String,
        message: String
    };

    connect = () => {
        const type = this.typeValue;
        const message = this.messageValue;

        if (type === 'error') {
            toastr.error(message);
        } else if (type === 'notice') {
            toastr.notice(message);
        } else if (type === 'success') {
            toastr.success(message);
        }

        $(this.element).remove();
    };
}
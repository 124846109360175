import {Controller} from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    connect = () => {
        $(this.element).on('click', this.handleClick);
    };

    disconnect = () => {
        $(this.element).off('click', this.handleClick)
    }

    handleClick = () => {
        $(this.element).parents('.input-group').find('input').val('').trigger('change');
    }
}
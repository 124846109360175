import {Controller} from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
    static values = {
        target: String,
        show: Boolean,
        size: String
    };

    connect = () => {
        let $modal = $('#' + this.targetValue)

        if (!$modal.hasClass('modal')) {
            $modal = $modal.parents('.modal')
        }

        if ($modal) {
            $modal.on('hidden.bs.modal', () => {
                $modal.find('turbo-frame').removeAttr('src').html('');
            });

            let $modalDialog = $modal.find('.modal-dialog');
            $modalDialog.removeClass('modal-sm modal-md modal-lg');

            if (this.sizeValue) {
                $modalDialog.addClass(this.sizeValue);
            }

            $modal.modal(this.showValue ? 'show' : 'hide');
        }

        $(this.element).remove();
    };
}
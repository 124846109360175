import './style/app.scss';

import './bootstrap';

import 'bootstrap';
import '@popperjs/core';
import '@tabler/core/dist/js/tabler';

import * as Turbo from '@hotwired/turbo';
import './forms';
import './keyboard';
import './toastr';

Turbo.start();

const load = () => {
    document.removeEventListener('DOMContentLoaded', load, false);
    document.body.classList.remove('c-no-layout-transition');
};
document.addEventListener('DOMContentLoaded', load);
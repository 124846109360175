import toastr from 'toastr';

toastr.options = {
    closeButton: true,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '3000',
    hideDuration: '500',
    showEasing: 'swing',
    hideEasing: 'swing',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};

window.toastr = toastr;
export default toastr;

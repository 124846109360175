import {Controller} from 'stimulus';
import {createListUrl, debounce} from '../utils';
import $ from 'jquery';

export default class extends Controller {
    static values = {
        list: String,
    };

    connect = () => {
        const $list = $('#' + this.listValue);
        if ($list) {
            $(this.element).on('change keyup', debounce((event) => {
                if ($(event.target).is(':visible')) {
                    const searchQuery = $(event.target).val();

                    const currentListUrl = $list.attr('src');
                    const newListUrl = createListUrl(currentListUrl, {'search': searchQuery});

                    if (currentListUrl !== newListUrl) {
                        $list.attr('src', newListUrl);
                    }
                }

            }, 250))
        }
    }

    disconnect = () => {
        $(this.element).off('change keyup');
        $(this.element).val('');
    }
}

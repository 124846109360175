export const createListUrl = (url, parameters) => {
    const urlParts = url.split('?')
    const base = urlParts[0];

    let queryString = {};
    if (urlParts.length > 1) {
        queryString = parseQuery(urlParts[1]);
    }

    if (queryString['lazy']) {
        delete queryString['lazy'];
    }
    if (queryString['page']) {
        delete queryString['page'];
    }

    for (let key in parameters) {
        queryString[key] = parameters[key];
    }

    return base + '?' + buildQuery(queryString);
}

export const parseQuery = (queryString) => {
    if (queryString.indexOf('?') >= 0) {
        queryString = queryString.substr(queryString.indexOf('?') + 1);
    }

    let query = {};
    const pairs = queryString.split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
};

export const buildQuery = (obj) => {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }

    return str.join('&');
}

export const debounce = (func, delay) => {
    let inDebounce

    return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() => func.apply(context, args), delay)
    }
}
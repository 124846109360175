import $ from 'jquery';

import 'jquery-ui/ui/unique-id';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_nl';

import './dependon';

$('form [data-dependent]').dependOn().dependInit();

$('form').validate({
    errorElement: 'em',
    errorPlacement: function errorPlacement(error, element)
    {
        error.addClass('invalid-feedback');
        if (element.prop('type') === 'checkbox') {
            error.insertAfter(element.parent('label'));
        } else {
            error.insertAfter(element);
        }
    },
    highlight: function highlight(element)
    {
        $(element).addClass('is-invalid').removeClass('is-valid');
    },
    unhighlight: function unhighlight(element)
    {
        $(element).addClass('is-valid').removeClass('is-invalid');
    },
});

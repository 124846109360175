import {Controller} from 'stimulus';
import {createListUrl, parseQuery} from '../utils';
import $ from 'jquery';

export default class extends Controller {

    connect = () => {
        const $element = $(this.element);
        const $loader = $($element.html());

        this.id = $element.attr('id');
        this.loader = $loader;

        $loader.attr('id', this.id + '-lazy-loader').hide();
        $element.parent().append($loader);

        $(window).on('turbo:before-stream-render', this.onStreamRender);
        $(window).on('scroll', this.onScroll).trigger('scroll');
    }

    disconnect = () => {
        $(window).off('turbo:before-stream-render', this.onStreamRender);
        $(window).off('scroll', this.onScroll);
    }

    onStreamRender = (event) => {
        if ($(event.target).attr('target') === this.id + '-pagination') {
            this.activePageNumber = 1;
            this.lastPageNumber = 1;

            const templateHtml = $(event.target).find('template').html();

            const activePageText = $(templateHtml).find('.page-item.active').text();
            if (activePageText) {
                this.activePageNumber = parseInt(activePageText) ?? 1;
            }

            const lastPageUrl = $(templateHtml).find('.page-item.last a[href]').attr('href');
            if (lastPageUrl) {
                const lastPageUrlParams = parseQuery(lastPageUrl);
                this.lastPageNumber = parseInt(lastPageUrlParams['page']) ?? 1;
            }

            if (this.activePageNumber < this.lastPageNumber) {
                this.loader.show();
                setTimeout(function () {
                    $(window).trigger('scroll');
                }, 50);
            } else {
                this.loader.hide();
            }
        }
    }

    onScroll = () => {
        if (this.isInViewport(this.loader)) {
            const $element = $(this.element);

            const nextPage = (this.activePageNumber ?? 1) + 1;

            if (!this.lastPageNumber || this.lastPageNumber >= nextPage) {
                const nextUrl = createListUrl($element.attr('src'), {'lazy': 1, 'page': nextPage});
                $element.attr('src', nextUrl);
            }
        }
    }

    isInViewport = ($element) => {
        if ($element.is(':hidden')) {
            return false;
        }

        const elementTop = $element.offset().top;
        const elementBottom = elementTop + $element.outerHeight();

        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
}

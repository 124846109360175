import {Controller} from 'stimulus';
import {connectStreamSource, disconnectStreamSource} from '@hotwired/turbo';

export default class extends Controller {
    static values = {
        topic: String,
        hub: String,
    };
    eventSource;

    initialize = () => {
        const errorMessages = [];
        if (!this.hasHubValue) {
            errorMessages.push(`A "hub" value pointing to the Mercure hub must be provided.`);
        }
        if (!this.hasTopicValue) {
            errorMessages.push(`A "topic" value must be provided.`);
        }
        if (errorMessages.length) {
            throw new Error(errorMessages.join(' '));
        }

        const u = new URL(this.hubValue);
        u.searchParams.append('topic', this.topicValue);

        this.url = u.toString();
    }

    connect = () => {
        this.eventSource = new EventSource(this.url, {withCredentials: true});
        connectStreamSource(this.eventSource);
    }

    disconnect = () => {
        this.eventSource.close();
        disconnectStreamSource(this.eventSource);
    }
}

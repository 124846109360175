import $ from 'jquery';

const keyboard = {
    init: function () {
        $('html').on('turbo:render', this.bindEvents);
        this.bindEvents();
    },

    bindEvents: () => {
        const $body = $('body');
        let ctrl = false;

        $body.keydown((event) => {
            if (event.key === 'Escape') {
                $body.find('.modal.show .btn-close').first().trigger('click');
            }

            if (ctrl && event.key === 's') {
                $body.find('.modal.show button[id$="_submit"]').first().trigger('click');

                return false;
            }

            ctrl = (event.key === 'Control');
        });

        $body.keyup((event) => {
            if (event.key === 'Control') {
                ctrl = false;
            }
        });
    }
}
keyboard.init();
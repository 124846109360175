import $ from 'jquery';

$.fn.dependOn = function dependOn() {
    this.each(function findDependencies() {
        const dependent = $(this);
        const depend = $(this).data('dependent');
        let dependValues = $(this).data('dependent-values');
        let dependSelector = $(this).data('dependent-selector');
        let dependeeSelector = $(this).data('dependee-selector');
        const dependKeepVisible = $(this).data('dependent-keep-visible');
        const dependSkipVisibilityCheck = $(this).data('dependent-skip-visibility-check');

        if (!$.isArray(dependValues)) {
            dependValues = [dependValues];
        }

        if (typeof dependSelector === 'undefined') {
            dependSelector = 'div.form-group';
        }
        if (typeof dependeeSelector === 'undefined') {
            dependeeSelector = dependSelector;
        }

        dependent.parents('form')
            .find(`[name*="[${depend}]"],[name="${depend}"]`)
            .each(function handleDependencies() {
                $(this)
                    .on('change', function onChangeHandler() {
                        const wrapper = (dependeeSelector === '.') ? dependent : dependent.parents(dependeeSelector);
                        const inputs = wrapper.find('[name]');
                        const values = [];

                        if ($.inArray($(this).attr('type'), ['radio', 'checkbox']) >= 0) {
                            $(this).parents(dependSelector).find(`[name="${$(this).attr('name')}"]`).each(function assignValues() {
                                if (this.checked) {
                                    values[values.length] = $(this).val();
                                } else {
                                    values[values.length] = 0;
                                }
                            });
                        } else {
                            values[values.length] = $(this).val();
                        }

                        if (values.length < 1) {
                            values[values.length] = 0;
                        }

                        let match = false;
                        for (let i = 0; i < values.length; i += 1) {
                            let val = values[i];
                            if ($.isNumeric(val)) {
                                val = parseFloat(val);
                            }

                            if ($.inArray(val, dependValues) >= 0 || (dependValues.length === 1 && dependValues[0] === '*' && val !== '' && val !== null)) {
                                match = true;
                            }
                        }

                        if ((dependSkipVisibilityCheck || $(this).is(':visible')) && match) {
                            wrapper.show();
                            $.each(inputs, (i, input) => {
                                $(input).attr('disabled', false);
                            });
                        } else {
                            inputs.attr('disabled', true).removeClass('error');

                            if (!dependKeepVisible) {
                                wrapper.hide();
                            }
                        }

                        inputs.first().trigger('change');
                    });
            });
    });

    return this;
};

$.fn.dependInit = function dependInit() {
    const triggered = [];

    this.each(function initDependency() {
        const formId = $(this).parents('form').uniqueId().attr('id');
        const dependOn = $(this).data('dependent');

        if ($.inArray(`${dependOn}-=-${formId}`, triggered) < 0) {
            const $input = $(this).parents('form').find(`[name*="[${dependOn}]"], [name="${dependOn}"]`).first();
            $input.trigger('change');

            triggered[triggered.length] = `${dependOn}-=-${formId}`;
        }
    });
};

import {Controller} from 'stimulus';
import {createListUrl} from '../utils';
import $ from 'jquery';

export default class extends Controller {
    static values = {
        target: String
    };

    connect = () => {
        const $turboFrame = $('#' + this.targetValue);

        if ($turboFrame.length) {
            let src = $turboFrame.attr('src');
            if (this.targetValue.substring(this.targetValue.length - 5) === '-list') {
                src = createListUrl(src);
            }

            $turboFrame.removeAttr('src').attr('src', src);
        }

        $(this.element).remove();
    };
}